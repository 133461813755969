import { Component } from '@angular/core';

@Component({
  selector: 'app-route-not-found',
  templateUrl: './route-not-found.component.html',
  styleUrl: './route-not-found.component.css'
})
export class RouteNotFoundComponent {

}
