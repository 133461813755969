import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { LayoutsModule } from './layouts/layouts.module';

import { JwtInterceptor } from './interceptors/jwt/jwt.interceptor';

import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

const COMPONENTS = [
  AppComponent
];

const IMPORTS = [
  BrowserModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  HttpClientModule,
  FormsModule,
  SharedModule,
  LayoutsModule,
  ToastModule,
];

const PROVIDERS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useValue: Sentry.createErrorHandler({
      showDialog: true,
    }),
  },
  {
    provide: APP_INITIALIZER,
    useFactory: () => () => { },
    deps: [Sentry.TraceService],
    multi: true,
  },
  {
    provide: Sentry.TraceService,
    deps: [Router],
  },
  MessageService
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...IMPORTS, AppRoutingModule],
  providers: [...PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }