import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { RouteNotFoundComponent } from './components/route-not-found/route-not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { CallbackComponent } from './components/callback/callback.component';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

const COMPONENTS = [
  LoaderComponent,
  SetPasswordComponent,
  RouteNotFoundComponent,
  ForgotPasswordComponent,
  CallbackComponent
];

const PRIME_NG_IMPORTS = [
  ButtonModule,
  InputTextModule
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...PRIME_NG_IMPORTS
  ],
  exports: [...COMPONENTS]
})
export class SharedModule { }
