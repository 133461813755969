import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-super-admin-sidebar',
  templateUrl: './super-admin-sidebar.component.html',
  styleUrl: './super-admin-sidebar.component.css'
})
export class SuperAdminSidebarComponent implements OnInit {
  shoSidebar: boolean = false;
  userInfo: any;

  constructor(
    private serverService: ServerService
  ) {}

  ngOnInit() {
    const authRes = localStorage.getItem('authRes');
    if (authRes) {
      this.userInfo = JSON.parse(authRes);
    }
  }

  toggleSidebar() {
    this.shoSidebar = !this.shoSidebar;
  }

  onLogout() {
    this.serverService.showLoader();
    let jwtToken = localStorage.getItem('jwtToken')
      ? localStorage.getItem('jwtToken')
      : '';

    this.serverService.revokeSession('organization', jwtToken);
  }
}
