<section class="login">
    <div class="container mx-auto">
        <div class="login-box">

            <!-- SecureSlice Logo -->
            <div class="text-center">
                <div class="flex justify-center items-center">
                    <img src="../../../assets/images/logo.png" alt="" class="img-fluid img-1" />
                </div>
            </div>

            <!-- Actual Content -->
            <div class="login-inner-box">
                <div class="text-center">
                    <h3>Set Your Password</h3>
                </div>

                <form [formGroup]="passwordForm" (ngSubmit)="changePassword()" id="login">

                    <div class="form-control my-3">
                        <div class="input-wrapper">
                            <label>New Password</label>

                            <input pInputText formControlName="password" placeholder="Enter password"
                                [type]="showPassword ? 'text' : 'password'"
                                [class.border-red]="passwordForm.controls['password'].invalid" />

                            <i class="pi show-password" [ngClass]="showPassword ? 'pi-eye-slash' : 'pi-eye'"
                                (click)="togglePasswordFieldVisibility()">
                            </i>
                        </div>
                    </div>

                    <div class="form-control my-3">
                        <div class="input-wrapper">
                            <label>Confirm Password</label>
                    
                            <input pInputText formControlName="confirmPassword" placeholder="Enter password"
                                [type]="showConfirmPassword ? 'text' : 'password'"
                                [class.border-red]="passwordForm.controls['confirmPassword'].invalid" />
                    
                            <i class="pi show-password" [ngClass]="showConfirmPassword ? 'pi-eye-slash' : 'pi-eye'"
                                (click)="toggleConfirmPasswordFieldVisibility()">
                            </i>
                        </div>
                    </div>

                    <div class="password-complexity mb-4">
                        <ul class="p-0">

                            <li [class.done]="!passwordForm.get('password')?.errors?.['uppercase']">
                                <i class="pi pi-check"></i> 1 uppercase letter
                            </li>
                            <li [class.done]="!passwordForm.get('password')?.errors?.['lowercase']">
                                <i class="pi pi-check"></i> 1 lowercase letter
                            </li>
                            <li [class.done]="!passwordForm.get('password')?.errors?.['specialCharacter']">
                                <i class="pi pi-check"></i> 1 special character
                            </li>
                            <li [class.done]="!passwordForm.get('password')?.errors?.['numericCharacter']">
                                <i class="pi pi-check"></i> 1 numeric character
                            </li>
                            <li
                                [class.done]="passwordForm.get('password')?.value && !passwordForm.get('password')?.errors?.['minlength'] && !passwordForm.get('password')?.errors?.['maxlength']">
                                <i class="pi pi-check"></i> 6 to 16 characters long
                            </li>

                        </ul>
                    </div>
                    <button class="btn btn-block btn-secondary text-center rounded-full flex align-center mt-10"
                        [disabled]="passwordForm.invalid || (passwordForm.get('password')?.value !== passwordForm.get('confirmPassword')?.value)">
                        Continue
                    </button>
                </form>
            </div>
        </div>
    </div>
</section>