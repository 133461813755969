<aside class="main-sidebar" [ngClass]="showSidebar ? 'toggled' : ''">
  <!-- <a href="javascript:void(0)" (click)="toggleSidebar()" id="menu-toggle" class="menu-toggle">
      <i class="pi pi-chevron-left"></i>
    </a> -->
  <div class="sidebar-top-wrap">
    <div class="dasboard-logo-wrap">
      <img src="../../../assets/images/logo.png" alt="logo" class="img-fluid img-1" />
    </div>

    <nav class="sidebar-nav">
      <h3 class="sidebar-left-padding mb-3">Projects</h3>
      <ul class="m-0 p-0">
        @for (project of projects ; track $index){
        <li>
          <a href="javascript:void(0)" (click)="selectProject(project.projectId)">
            <div class="menu-label" [class.text-primary]="project.projectId === currentProjectId">
              {{project.name}}
            </div>
          </a>
        </li>
        }
      </ul>
      <div class="sidebar-left-padding mt-3">
        <p-button [outlined]="true" label="Add Project" severity="secondary" (click)="onAddProjectClick()"></p-button>
      </div>
    </nav>
  </div>

  @if(userInfo){
  <div class="sidebar-profile-wrap">
    <div class="sidebar-profile">
      <div class="profile-image initials-style">
        {{initials}}
      </div>
      <div class="sidebar-prof-detail">
        <h6 class="m-0">{{ userInfo?.name }}</h6>
        <!-- <p class="m-0 text-secondary">{{ userInfo?.userEmail }}</p> -->
      </div>
      <div class="overlay-menu">
        <p-overlayPanel #op>
          <ul class="profile-context-menu m-0">
            <li>
              <a href="javascript:void(0)" class="text-secondary text-decoration-none" (click)="onLogout()">Logout</a>
            </li>
          </ul>
        </p-overlayPanel>
        <a href="javascript:void(0)" (click)="op.toggle($event)" class="text-secondary">
          <i class="pi pi-ellipsis-h"></i>
        </a>
      </div>
    </div>
  </div>
  }
</aside>