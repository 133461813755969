<aside class="main-sidebar" [ngClass]="shoSidebar ? 'toggled' : ''">
    <a href="javascript:void(0)" (click)="toggleSidebar()" id="menu-toggle" class="menu-toggle">
        <i class="pi pi-chevron-left"></i>
    </a>
    <div class="sidebar-top-wrap">
        <div class="dasboard-logo-wrap">
            <img src="../../../assets/images/logo.png" alt="logo" class="img-fluid img-1" />
        </div>

        <nav class="sidebar-nav">
            <ul class="m-0 p-0">
                <li>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/admin/dashboard">
                        <div class="sidebar-icon">
                            <i class="pi pi-building"></i>
                        </div>
                        <div class="menu-label">Organization</div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)" routerLinkActive="active" routerLink="/admin/logs">
                        <div class="sidebar-icon">
                            <i class="pi pi-server"></i>
                        </div>
                        <div class="menu-label">Logs</div>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <div class="sidebar-icon">
                            <i class="pi pi-th-large"></i>
                        </div>
                        <div class="menu-label">Applications</div>
                    </a>
                </li>
            </ul>
        </nav>
    </div>

    <div class="sidebar-profile-wrap">
        <div class="sidebar-profile">
            <div class="rounded profile-image">
                <img src="../../../assets/images/user-profile.png" alt="Avatar" />
            </div>
            <div class="sidebar-prof-detail">
                <h6 class="m-0">{{ userInfo?.member?.name }}</h6>
            </div>
            <div class="overlay-menu">
                <p-overlayPanel #op>
                    <ul class="profile-context-menu m-0">
                        <li>
                            <a href="javascript:void(0)" class="text-secondary text-decoration-none"
                                (click)="onLogout()">Logout</a>
                        </li>
                    </ul>
                </p-overlayPanel>
                <a href="javascript:void(0)" (click)="op.toggle($event)" class="text-secondary">
                    <i class="pi pi-ellipsis-h"></i>
                </a>
            </div>
        </div>
    </div>
</aside>