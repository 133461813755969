import { Component } from '@angular/core';

@Component({
  selector: 'app-super-admin-layout',
  templateUrl: './super-admin-layout.component.html',
  styleUrl: './super-admin-layout.component.css'
})
export class SuperAdminLayoutComponent {

}
