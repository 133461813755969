import { ServerService } from 'src/app/services/server/server.service';
import { Injectable } from '@angular/core';
import { User } from 'src/app/interfaces/user.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  currentUserSubject: BehaviorSubject<User>;
  currentUser$: Observable<User>;


  constructor(private serverService: ServerService) {
    this.currentUserSubject = new BehaviorSubject<User>({} as any);
    this.currentUser$ = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): User {
    return this.currentUserSubject.value
  }

  getCurrentUser() {
    if (!localStorage.getItem('jwtToken')) {
      return true;
    }

    const api = 'org-admin/self';
    
    return this.serverService.get(api).subscribe({
      next: (res: any) => {
        this.currentUserSubject.next(res);
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
      }
    });
  }

}
