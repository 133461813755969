<main class="dashboard-main-wrapper ">
    <app-project-sidebar></app-project-sidebar>
      <article class="main-content-area">
        <div class="content-container">

          <router-outlet></router-outlet>

        </div>
      </article>
</main>
