import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ServerService } from '../server/server.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  projectId!: string;
  private currentProjectSubjectDetails: BehaviorSubject<any> = new BehaviorSubject(null);
  currentProject$: Observable<any> = this.currentProjectSubjectDetails.asObservable();
  private httpSubscription!: Subscription;

  constructor(
    private serverService: ServerService
  ) { }

  setCurrentProjectId(projectID: string): void { 
    if(this.httpSubscription) this.httpSubscription.unsubscribe();
    this.projectId = projectID;
    this.getProjectDetails();
  }

  getProjectDetails(): void {
    this.serverService.showLoader();
    const api = `projects/details/${this.projectId}`;

    this.httpSubscription = this.serverService.get(api).subscribe({
      next: (response: any) => {
        this.setProjectDetails(response.data);
        this.serverService.dismissLoader();
      },
      error: (error: HttpErrorResponse) => {
        console.error(error);
        this.serverService.dismissLoader();
      }
    })
  }

  setProjectDetails(data: any): void {
    this.currentProjectSubjectDetails.next(data);
  }
}
