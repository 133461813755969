import { Component } from '@angular/core';

@Component({
  selector: 'app-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrl: './project-layout.component.css'
})
export class ProjectLayoutComponent {

}
