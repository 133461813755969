<section class="login login-sec-wrapper">
    <form [formGroup]="validateEmailForm" id="login" (ngSubmit)="onEmailSubmit()" *ngIf="!sentPassword">
        <div class="container">
            <div class="login-logo-wrapper text-center">
                <img src="../../../assets/images/logo.png" alt="logo" class="img-fluid img-1" />
            </div>
            <div class="login-box-wrapper">
                <h3>Forgot Password</h3>

                <div class="form-wrapper w-100">
                    <div class="input-wrapper">
                        <label>Email</label>
                        <span *ngIf="isEmailInvalid()" class="invalid-email">Invalid Email</span>
                        <input [class.border-red]="isEmailInvalid()" formControlName="userEmail" type="email"
                            placeholder="Email" class="input input-bordered w-full max-w-xs" [ngClass]="{
                'border-red':
                  validateEmailForm.controls.userEmail.invalid && invalidFlag
              }" pInputText />
                    </div>
                    <p-button type="submit" pRipple label="Submit"></p-button>
                </div>
            </div>
        </div>
    </form>
    <label *ngIf="sentPassword">Reset password link has been sent successfully. Please check your email!</label>
    <div>
    </div>
</section>