@if(userInfo?.userEmail){
<aside class="main-sidebar" [ngClass]="showSidebar ? 'toggled' : ''">
  <a href="javascript:void(0)" (click)="toggleSidebar()" id="menu-toggle" class="menu-toggle">
    <i class="pi pi-chevron-left"></i>
  </a>
  <div class="sidebar-top-wrap">
    <div class="dasboard-logo-wrap">
      <img src="../../../assets/images/logo.png" alt="logo" class="img-fluid img-1" />
    </div>

    <nav class="sidebar-nav">
      <ul class="m-0 p-0">
        @for (menu of menuItems; track $index) {
        <li>
          <a href="javascript:void(0)" [routerLink]="menu.routerLink" routerLinkActive="active">
            <div class="sidebar-icon">
              <i class="pi" [ngClass]="menu.icon"></i>
            </div>
            <div class="menu-label">{{menu.name}}</div>
          </a>
        </li>
        }

        <!-- Projects -->
        <li *ngIf="this.userInfo.privileges['projects']">
          <a href="javascript:void(0)" (click)="openProjectsInNewTab()">
            <div class="sidebar-icon">
              <i class="pi pi-briefcase"></i>
            </div>
            <div class="menu-label">Projects</div>
          </a>
        </li>

        <!-- New Dashboard -->
        <li>
          <a href="javascript:void(0)" routerLink="new-dashboard" routerLinkActive="active">
            <div class="sidebar-icon">
              <i class="pi pi-home"></i>
            </div>
            <div class="menu-label">New Dashboard</div>
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="sidebar-profile-wrap">
    <div class="sidebar-profile">
      <div class="profile-image initials-style">
        {{initials}}
      </div>
      <div class="sidebar-prof-detail">
        <h6 class="m-0">{{ userInfo?.name }}</h6>
        <!-- <p class="m-0 text-secondary">{{ userInfo?.userEmail }}</p> -->
      </div>
      <div class="overlay-menu">
        <p-overlayPanel #op>
          <ul class="profile-context-menu m-0">
            <li>
              <a href="javascript:void(0)" class="text-secondary text-decoration-none" (click)="onLogout()">Logout</a>
            </li>
          </ul>
        </p-overlayPanel>
        <a href="javascript:void(0)" (click)="op.toggle($event)" class="text-secondary">
          <i class="pi pi-ellipsis-h"></i>
        </a>
      </div>
    </div>
  </div>
</aside>
}
