import { Component, inject } from '@angular/core';
import { SecurityService } from './services/security/security.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private securityService: SecurityService
  ) { }

  activeIndex: number = 0;
  ngOnInit(): void {
    this.securityService.setXFrameOptions();
  }
}
