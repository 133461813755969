import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateProjectComponent } from 'src/app/modules/projects-admin/create-project/create-project.component';
import { CommonService } from 'src/app/services/common/common.service';
import { ProjectService } from 'src/app/services/project/project.service';
import { ServerService } from 'src/app/services/server/server.service';
import { ModalHeaderComponent } from 'src/app/shared/components/standalone/modal-header/modal-header.component';

@Component({
  selector: 'app-project-sidebar',
  templateUrl: './project-sidebar.component.html',
  styleUrl: './project-sidebar.component.css'
})
export class ProjectSidebarComponent implements OnInit {
  showSidebar: boolean = false;
  userInfo!: any;
  initials: string = '';
  projects: any[] = [];
  currentProjectId: string = '';

  constructor(
    private dialogService: DialogService,
    private commonService: CommonService,
    private serverService: ServerService,
    private projectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.commonService.getCurrentUser();
    this.getCurrentUser();
    this.getProjectsList();
  }

  getCurrentUser(): void {
    this.commonService.currentUser$.subscribe({
      next: (res) => {
        if (res?.userEmail) {
          this.userInfo = res;
          this.initials = this.getInitials(this.userInfo.name); // Get initials
        }
      }
    });
  }

  getProjectsList(): void {
    // Get all the projects for the logged in user
    this.serverService.showLoader();

    const api: string = `projects/user`;
    this.serverService.get(api).subscribe({
      next: (response: any) => {
        this.serverService.dismissLoader();
        this.projects = response.projects;
        if (this.projects.length === 0) {
          this.onAddProjectClick();
        } else {
          this.currentProjectId = this.projects[0].projectId;
          this.projectService.setCurrentProjectId(this.currentProjectId);
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.serverService.dismissLoader();
      },
    });
  }

  selectProject(projectId: string): void {
    this.currentProjectId = projectId;
    this.projectService.setCurrentProjectId(projectId);
  }

  onAddProjectClick(): void {
    const dialogRef: DynamicDialogRef = this.dialogService.open(CreateProjectComponent, {
      contentStyle: { 'padding': '0' },
      templates: {
        header: ModalHeaderComponent,
      },
      data: {
        title: 'Create Project'
      }
    });

    // maximizing the opened dialog for full screen effect
    this.dialogService.dialogComponentRefMap.forEach(x => {
      x.instance.maximized = true;
    });

    // handling dialog closing
    dialogRef.onClose.subscribe((data: any) => {
      if(data) {
        this.getProjectsList();
      }
    });
  }

  onLogout(): void { 
    this.serverService.showLoader();
    this.serverService.logout();
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  getInitials(name: string): string {
    let initials = '';
    if (name && name.length > 0) {
      const nameParts = name.split(' '); // Split name into parts
      for (let part of nameParts) {
        if (part.length > 0) {
          initials += part.charAt(0).toUpperCase(); // Get first letter of each part
        }
      }
    }
    return initials;
  }
}
