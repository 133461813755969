<div class="create-signal-body">
    <div class="signal-cards-outer">
        <div class="create-project-form-wrapper">

            <div class="form-wrapper" [formGroup]="projectForm">
                <div class="input-wrapper">
                    <label for="name">Project Name</label>
                    <input id="name" pInputText formControlName="name" type="text" />
                </div>

                <div class="input-wrapper">
                    <label for="companyName">Company Name</label>
                    <input id="companyName" pInputText formControlName="companyName" type="text" />
                </div>

                <div class="input-wrapper">
                    <label for="website">Website</label>
                    <input id="website" pInputText formControlName="website" type="text" />
                </div>

                <div class="input-wrapper">
                    <label for="engagementCode">Engagement Code</label>
                    <input id="engagementCode" pInputText formControlName="engagementCode" type="text" />
                </div>

                <!-- <div>
                    <div class="d-flex justify-content-between">
                        <p>Collaborators</p>
                        <a href="javascript:void(0)" class="text-decoration-none">
                            Bulk import
                        </a>
                    </div>
                    <div class="words-listing-wrapper">
                        <div class="d-flex align-items-center">
                            <div>michelle.azme&#64;gmail.com</div>
                            <div>
                                <a href="javascript:void(0)" class="text-secondary text-decoration-none">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div>sophie.towner&#64;gmail.com</div>
                            <div>
                                <a href="javascript:void(0)" class="text-secondary text-decoration-none">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>earl.mckenzi&#64;gmail.com</div>
                            <div>
                                <a href="javascript:void(0)" class="text-secondary text-decoration-none">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex">
                            <div>ashlynn.brooke&#64;gmail.com</div>
                            <div>
                                <a href="javascript:void(0)" class="text-secondary text-decoration-none">
                                    <i class="pi pi-trash"></i>
                                </a>
                            </div>
                        </div>
                        <div class="d-flex p-2">
                            <input pInputText type="text" placeholder="Enter email" />
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="form-wrapper">
                <div class="input-wrapper">
                    <label for="collaborators">Collaborators</label>
                    <p-autoComplete id="collaborators" class="d-inline-block w-100" [(ngModel)]="selectedMembers"
                        [suggestions]="filteredMembers" (completeMethod)="filterMembers($event)" [multiple]="true">
                    </p-autoComplete>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="create-signal-footer d-flex justify-content-between align-items-center">
    <div class="align-items-center button-wrap d-flex">
        <p-button label="Back" [text]="true" [plain]="true" icon="pi pi-arrow-left"
            (onClick)="closeDialog()"></p-button>
        <a href="javascript:void(0)" class="text-secondary text-decoration-none">
            Need Help?
        </a>
    </div>
    <div class="button-wrap">
        <p-button label="Create" [rounded]="true" [disabled]="projectForm.invalid" (onClick)="handleSubmit()">
        </p-button>
    </div>
</div>