// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const localEnvVars = {
  production: false,
  baseUrl: 'http://localhost:3001/api/v1',
  redirect_uri: `http://localhost:4200/api/callback`
}

const deployEnvVars = {
  production: true,
  baseUrl: 'https://backend-dev.secureslice.com/api/v1',
  redirect_uri: `https://dev.secureslice.com/api/callback`
}

export const environment = {
  project_id: `project-test-eab753c9-dbca-401c-8443-964f3c4f0c1c`,
  secret_key: `secret-test-IvQqmlYCpGHr6Hsp7DNHVI-WC476TJ7jzKk=`,
  public_token: `public-token-test-f7a268b9-14a5-430b-93b8-7e8df6dfc8d8`,
  connection_id: `saml-connection-test-6f7f2e1b-ec0c-4c4f-98bc-e5cfd9619f69`,
  stytch_api_url: `https://test.stytch.com/v1/public/sso/start`,
  // ...localEnvVars,
  ...deployEnvVars
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */