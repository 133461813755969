import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private messageService: MessageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Get the JWT token from local storage
    const jwtToken = localStorage.getItem('jwtToken');

    if (jwtToken) {
      // Clone the request and add the JWT token to the headers
      //Bearer
      request = request.clone({
        setHeaders: {
          Authorization: `${jwtToken}`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.clear();
          localStorage.clear();
          this.navigateToAuth(request.url);
        }
        if(!request.url.includes('/org-admin/logout')) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
        }
        throw error;
      })
    );
  }

  navigateToAuth(requestURL: string): void {
    if (requestURL.includes('organization')) {
      this.router.navigate(['/super-admin-login']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
