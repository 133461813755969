import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setXFrameOptions() {
    const meta = this.renderer.createElement('meta');
    this.renderer.setAttribute(meta, 'http-equiv', 'X-Frame-Options');
    this.renderer.setAttribute(meta, 'content', 'DENY');
    this.renderer.appendChild(document.head, meta);
  }
}
