import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  isLoading: boolean = false;

  userOldPassword!: string;

  // get the api baseUrl from environment file !

  private readonly baseUrl = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  // http server calls !

  get<T>(endpoint: string, options?: any): Observable<any> {
    if (options) {
      return this.http.get<T>(`${this.baseUrl}/${endpoint}`, options);
    }
    return this.http.get<T>(`${this.baseUrl}/${endpoint}`);
  }

  getStytch<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(`${endpoint}`);
  }

  post<T>(endpoint: string, data: any, options?: any): Observable<any> {
    if (options) {
      return this.http.post<T>(`${this.baseUrl}/${endpoint}`, data, options);
    }
    return this.http.post<T>(`${this.baseUrl}/${endpoint}`, data);
  }

  put<T>(endpoint: string, data: any): Observable<T> {
    return this.http.put<T>(`${this.baseUrl}/${endpoint}`, data);
  }

  patch<T>(endpoint: string, data: any, query?: any): Observable<T> {
    return this.http.patch<T>(`${this.baseUrl}/${endpoint}`, data);
  }

  delete<T>(endpoint: string): Observable<T> {
    return this.http.delete<T>(`${this.baseUrl}/${endpoint}`);
  }

  logout() {
    this.post(`org-admin/logout`, {}).subscribe({
      next: () => {
        this.isLoading = false;
        this.router.navigate(['initialize-login']);
        localStorage.clear();
        localStorage.clear();
      },
      error: (error) => {
        this.isLoading = false;
        this.router.navigate(['initialize-login']);
        localStorage.clear();
        localStorage.clear();
        console.log(error);
      },
    });
  }

  revokeSession(type: string, jwtToken: string | null) {
    localStorage.removeItem('jwtToken');
    this.post(`${type}/revoke-session`, { session_jwt: jwtToken }).subscribe({
      next: () => {
        localStorage.removeItem('authRes');
        this.isLoading = false;
        type == 'organization'
          ? this.router.navigate(['super-admin-login'])
          : this.router.navigate(['login']);
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        type == 'organization'
          ? this.router.navigate(['super-admin-login'])
          : this.router.navigate(['login']);
        console.log(error);
      },
    });
  }

  showLoader() {
    this.isLoading = true;
  }

  dismissLoader(): void {
    this.isLoading = false;
  }
}
