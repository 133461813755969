// Standard Angular imports
import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Application's root module
import { AppModule } from './app/app.module';

// Initialize Sentry
Sentry.init({
  dsn: 'https://8cd9833618db8f152a97a65481daeae6@o4506123425808384.ingest.sentry.io/4506311141097472',
  environment: 'Dev-Frontend', // Customize the environment name as needed
  integrations: [
    new Sentry.BrowserTracing({
      // Specify which URLs to enable distributed tracing
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
    new CaptureConsole({
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
    }),
    new ExtraErrorData({
      depth: 3, // Customize the depth of extra error data
    }),
  ],
  // Set the sample rates for performance monitoring and session replay
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

// Enable production mode for Angular (unrelated to Sentry)
enableProdMode();

// Bootstrap the Angular application
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('Bootstrap success'))
  .catch((err) => {
    console.error(err);
    Sentry.captureException(err); // Send the error to Sentry
    // Optionally, display a user feedback dialog
    Sentry.showReportDialog({ eventId: Sentry.getCurrentHub().lastEventId() });
  });
