import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.css'
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private messageService :MessageService,
    private serverService :ServerService,
    private route :ActivatedRoute,
    ) {}

  invalidFlag: boolean = false;
  sentPassword: boolean = false;
  type: string = '';

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.type = params['type'];
    });
  }

  validateEmailForm = new FormGroup({
    userEmail: new FormControl('', [Validators.required, Validators.email]),
  });

  onEmailSubmit() {
    this.serverService.showLoader();
    if (this.validateEmailForm.valid) {
      this.serverService
        .post('auth/forgot-password', {
          userEmail: this.validateEmailForm.controls.userEmail.value,
          type: this.type,
        })
        .subscribe({
          next: (response: any) => {
            if (response.status == 'success') {
              this.serverService.dismissLoader();
              console.log('response', response);
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail:
                  'Reset password link has been sent successfully. Please check your email!',
              });
              this.sentPassword = true;
              this.validateEmailForm.reset();
            } else {
              this.serverService.dismissLoader();
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Something went wrong. Please try again',
              });
            }
          },
          error: (error) => {
            this.serverService.dismissLoader();
            if (error) {
              // Handle 404 error
              const errorMessage = 'User does not exist with provided email!';
              // this.messageService.add({
              //   severity: 'error',
              //   summary: 'Error',
              //   detail: errorMessage,
              // });
            } else {
              // Handle other errors
              // this.messageService.add({
              //   severity: 'error',
              //   summary: 'Error',
              //   detail: 'Something went wrong. Please try again.',
              // });
            }
          },
        });
    } else {
      this.invalidFlag = true;
      this.serverService.dismissLoader();
    }
  }

  isEmailInvalid(): boolean {
    const emailControl = this.validateEmailForm.controls.userEmail;
    return emailControl.invalid && (emailControl.dirty || emailControl.touched);
  }
}
