import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
} from '@angular/router';
@Injectable({
  providedIn: 'root',
})
class SuperAdminPermissionsService {
  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (localStorage.getItem('jwtToken') && localStorage.getItem('authRes')) {
      return true;
    } else {
      // Redirect to the login page or any other page
      this.router.navigate(['/super-admin-login']);
      return false;
    }
  }
}

export const SuperAdminAuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(SuperAdminPermissionsService).canActivate(next, state);
};
