import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-organization-sidebar',
  templateUrl: './organization-sidebar.component.html',
  styleUrl: './organization-sidebar.component.css'
})
export class OrganizationSidebarComponent implements OnInit {

  sideNavMenu = [
    {
      name: 'Dashboard',
      routerLink: '/organization/dashboard',
      icon: 'pi-home',
      privilegeRequired: "dashboard"
    },
    {
      name: 'Global Policy',
      routerLink: '/organization/global-policy',
      icon: 'pi-server',
      privilegeRequired: "globalPolicies"
    },
    {
      name: 'Signals',
      routerLink: '/organization/signals',
      icon: 'pi-wifi',
      privilegeRequired: "signalAdministration"
    },
    {
      name: 'Users',
      routerLink: '/organization/users',
      icon: 'pi-users',
      privilegeRequired: "userManagement"
    },
    {
      name: 'Applications',
      routerLink: '/organization/applications',
      icon: 'pi-th-large',
      privilegeRequired: "applications"
    },
    {
      name: 'Settings',
      routerLink: '/organization/settings',
      icon: 'pi-cog'
    }
  ]

  showSidebar: boolean = false;
  userInfo: any;
  initials: string = ''; // Initialize initials
  menuItems: any[] = [];

  constructor(
    private router: Router,
    private serverService: ServerService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.getCurrentUser()
    this.getCurrentUser();
  }

  getCurrentUser(): void {
    this.commonService.currentUser$.subscribe({
      next: (res) => {
        if (res?.userEmail) {
          this.userInfo = res;
          this.initials = this.getInitials(this.userInfo.name); // Get initials
          this.fetchMenu()
        }
      }
    });
  }

  fetchMenu(): void {
    if(this.userInfo.privileges['projects']) {
      this.router.navigate(['/projects']);
    } else {
      this.menuItems = this.userInfo.firstOrgAdmin ? this.sideNavMenu : this.sideNavMenu.filter((menu: any) => {
        if (!menu.privilegeRequired) return menu;
        if (menu.privilegeRequired && this.userInfo.privileges[menu.privilegeRequired] == true) {
          return menu;
        }
      });
    }
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
  }

  onLogout() {
    this.serverService.showLoader();
    this.serverService.logout();
  }

  getInitials(name: string): string {
    let initials = '';
    if (name && name.length > 0) {
      const nameParts = name.split(' '); // Split name into parts
      for (let part of nameParts) {
        if (part.length > 0) {
          initials += part.charAt(0).toUpperCase(); // Get first letter of each part
        }
      }
    }
    return initials;
  }

  openProjectsInNewTab() {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/projects`])
    );

    window.open(url, '_blank');
  }
}
