import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrl: './create-project.component.css'
})
export class CreateProjectComponent implements OnInit {
  projectForm!: FormGroup;
  orgMembers: string[] = [];
  filteredMembers: string[] = [];
  selectedMembers: string[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogRef: DynamicDialogRef,
    private messageService: MessageService,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.buildProjectForm();
    this.getOrgMembers();
  }

  getOrgMembers(): void {
    const api = 'projects/auto-suggest';

    this.serverService.get(api).subscribe({
      next: (res: any) => {
        console.log(res);
        this.orgMembers = res.data;
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      }
    })
  }

  buildProjectForm(): void {
    this.projectForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      companyName: this.fb.control('', Validators.required),
      website: this.fb.control('', Validators.required),
      engagementCode: this.fb.control('', Validators.required),
    });
  }

  handleSubmit(): void {
    const api = `projects`;
    const payload = {
      collaborators: this.selectedMembers,
      ...this.projectForm.value
    };
    this.serverService.showLoader();

    this.serverService.post(api, payload).subscribe({
      next: (response: HttpResponse<any>) => {
        console.log(response);
        this.messageService.add({ severity: 'success', summary: 'You are already Logged in!' })
        this.serverService.dismissLoader();
        this.dialogRef.close({ message: 'success' });
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.serverService.dismissLoader();
      }
    });
  }

  filterMembers(event: AutoCompleteCompleteEvent) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.orgMembers.length; i++) {
      let member = this.orgMembers[i];
      if (member.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(member);
      }
    }

    this.filteredMembers = filtered;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
