import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuperAdminLayoutComponent } from './super-admin/super-admin-layout/super-admin-layout.component';
import { OrganizationLayoutComponent } from './organization/organization-layout/organization-layout.component';
import { RouterModule } from '@angular/router';
import { SuperAdminSidebarComponent } from './super-admin/super-admin-sidebar/super-admin-sidebar.component';
import { OrganizationSidebarComponent } from './organization/organization-sidebar/organization-sidebar.component';

import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProductService } from '../services/product/product.service';
import { ProjectSidebarComponent } from './project/project-sidebar/project-sidebar.component';
import { ProjectLayoutComponent } from './project/project-layout/project-layout.component';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';

const COMPONENTS = [
  SuperAdminLayoutComponent,
  SuperAdminSidebarComponent,
  OrganizationLayoutComponent,
  OrganizationSidebarComponent,
  ProjectLayoutComponent,
  ProjectSidebarComponent
];

const PRIME_NG_IMPORTS = [
  OverlayPanelModule,
  ButtonModule
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    RouterModule,
    ...PRIME_NG_IMPORTS
  ],
  exports: [...COMPONENTS],
  providers: [ProductService,DialogService]
})
export class LayoutsModule { }
