import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ServerService } from 'src/app/services/server/server.service';
import { UppercaseValidator } from '../../validators/uppercase.validator';
import { LowercaseValidator } from '../../validators/lowercase.validator';
import { SpecialCharacterValidator } from '../../validators/special-character.validator';
import { NumericDigitValidator } from '../../validators/numeric-character.validator';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.css'
})
export class SetPasswordComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private serverService: ServerService,
    private messageService: MessageService
  ) { }

  token!: string;
  organization_id!: string
  showNavigationOption: boolean = false;
  isResetPassword: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;

  passwordForm!: FormGroup;

  ngOnInit() {
    this.buildPasswordForm();
    this.route.params.subscribe((params) => {
      if (params?.['token']) {
        this.token = params['token'];
        this.isResetPassword = true
      }
    });
    this.route.queryParams.subscribe((params) => {
      if (params?.['sessionToken']) {
        this.token = params['sessionToken'];
        this.organization_id = params['organization'] || 'abcd'
        this.isResetPassword = false
      }
    })
  }

  buildPasswordForm(): void {
    this.passwordForm = this.fb.group({
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(16),
        UppercaseValidator(),
        LowercaseValidator(),
        SpecialCharacterValidator(),
        NumericDigitValidator()
      ])),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  togglePasswordFieldVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordFieldVisibility(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  changePassword() {
    if (this.passwordForm.valid) {
      if (
        this.passwordForm.controls['password'].value !==
        this.passwordForm.controls['confirmPassword'].value
      ) {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'New and confirm password does not match!' });
        return;
      }

      this.serverService.showLoader();
      if (this.isResetPassword) {
        this.resetPassword();
      } else {
        this.setPassword();
      }

    } else {
      this.serverService.dismissLoader();
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: this.isResetPassword ? 'Password reset failed' : 'Password set failed!' });
    }
  }

  resetPassword(): void {
    const api: string = `auth/reset-password/${this.token}`;
    const payload: any = this.passwordForm.value;

    this.serverService.post(api, payload).subscribe({
      next: (response: any) => {
        if (response) {
          console.log('here is the response', response);
          console.log('here is the type', response.type);
          this.messageService.add({ severity: 'success', summary: 'Success!', detail: 'Password reset successfully!' });
          this.serverService.dismissLoader();
          this.router.navigate(['/initialize-login']);
        } else {
          this.serverService.dismissLoader();
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Password reset failed. Please try again.' });
        }
      },
      error: (error) => {
        this.serverService.dismissLoader();
        // this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Password reset failed. Please try again.' });
        console.error(error);
      },
    });
  }

  setPassword(): void {
    const api: string = `auth/set-password/${this.token}`;
    const payload: any = { ...this.passwordForm.value, orgID: this.organization_id };

    this.serverService.post(api, payload).subscribe({
      next: (res) => {
        this.messageService.add({ severity: 'success', summary: 'Success!', detail: 'Password reset successfully!' });
        this.serverService.dismissLoader()
        this.router.navigate(['/initialize-login'])
      },
      error: (err) => {
        this.serverService.dismissLoader();
        // this.messageService.add({ severity: 'error', summary: 'Error!', detail: err.error.message });
      }
    });
  }
}
