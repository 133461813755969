import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.css'
})
export class CallbackComponent implements OnInit {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serverService: ServerService,
  ) { }

  ngOnInit() {
    this.serverService.showLoader();
    this.captureQueryParams();
  }

  captureQueryParams(): void {
    this.activatedRoute.queryParams.subscribe(async (params) => {

      const token: string = params['token'];
      const tokenType: string = params['stytch_token_type'];

      switch (tokenType) {
        case "sso":
          // SSO login
          this.authenticateSSOToken(token);
          break;
        case "multi_tenant_magic_links":
          // invite user
          this.navigateToSetPassword(token);
          break;
        case "multi_tenant_passwords":
          // reset password
          this.navigateToResetPassword(token);
          break;
        default:
          this.routeBackToLogin();
      }
    });
  }

  authenticateSSOToken(ssoToken: string): void {
    const api: string = 'auth/authenticate-sso-token';
    const payload: any = {
      sso_token: ssoToken,
    };

    this.serverService.post(api, payload).subscribe({
      next: (response: any) => {
        this.setLocalStorageData(response);
        this.navigateToDashboard(response);
        this.serverService.dismissLoader();
      },
      error: (err: HttpErrorResponse) => {
        console.error(err);
        this.routeBackToLogin();
        this.serverService.dismissLoader();
      }
    })
  }

  navigateToSetPassword(token: string): void {
    const api: string = `auth/magic-link/${token}`;
    this.serverService.get(api).subscribe({
      next: (res: any) => {
        this.serverService.dismissLoader();
        const queryParams = {
          queryParams:
          {
            sessionToken: res.session_token,
            organization: res.member.organization_id
          }
        };
        this.router.navigate(['set-password'], queryParams);
      },
      error: (err) => {
        // this.messageService.add({
        //   severity: 'error',
        //   summary: 'Magic Link Authentication Failed!',
        //   detail: err.message,
        // })
      }
    });
  }

  navigateToDashboard(authResponse: any): void {
    if ('user' in authResponse) {
      const user = authResponse['user'];
      const canUserLogin = this.checkUserPrivileges(user);

      if (canUserLogin) {
        localStorage.setItem('orgId', user.orgId);
        localStorage.setItem('jwtToken', authResponse.token);

        // Route user to Organization Admin's Dashboard
        this.router.navigate(['organization/dashboard']);
      } else {
        this.routeBackToLogin();
      }
    } else {
      // Route user to Super Admin's Dashboard
      this.router.navigate(['admin/dashboard']);
    }
  }

  navigateToResetPassword(token: string): void {
    this.serverService.dismissLoader();
    this.router.navigate([`reset-password/${token}`])
  }


  setLocalStorageData(authResponse: any): void {
    localStorage.setItem('authRes', JSON.stringify(authResponse.data));
    localStorage.setItem('jwtToken', authResponse.data.session_jwt);
  }

  checkUserPrivileges(user: any): boolean {
    if (user.privileges.dashboard) {
      return true;
    }
    return false;
  }

  routeBackToLogin(): void {
    this.router.navigate(['']);
  }
}
