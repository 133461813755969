import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

type privilege = 'dashboard' | 'userManagement' | 'settings' | 'signalAdministration' | 'globalPolicies'

export const AuthorizationGuard: CanActivateFn = (route, state) => {

  const commonService = inject(CommonService)
  const router = inject(Router)

  return new Promise((resolve, reject) => {
    commonService.currentUser$.subscribe({
      next: (user) => {
        if (user.userEmail) {
          const privilegeRequired: privilege = route.data['privilegeRequired']

          if (privilegeRequired && user.privileges[privilegeRequired] === false) {
            router.navigate(['/organization/dashboard'])
            resolve(false)
          }

          resolve(true)

        } else {

          commonService.getCurrentUser();

        }
      }
    })
  })

};
