import { CommonService } from 'src/app/services/common/common.service';
import { Component, OnInit } from '@angular/core';
import { ServerService } from 'src/app/services/server/server.service';

@Component({
  selector: 'app-organization-layout',
  templateUrl: './organization-layout.component.html',
  styleUrl: './organization-layout.component.css'
})
export class OrganizationLayoutComponent implements OnInit {
  orgInfo!: any;
  userInfo: any;

  constructor(
    private serverService: ServerService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getCurrentUser()
  }

  getCurrentUser() {
    this.commonService.currentUser$.subscribe({
      next: (res: any) => {
        if (res?.userEmail) {
          this.userInfo = res
          if(this.orgInfo) {
            this.getOrgInfo();
          }
        }
      }
    })
  }

  getOrgInfo() {
    this.serverService?.showLoader();
    this.serverService
      .get('org-admin/get-org-details')
      .subscribe({
        next: (res: any) => {
          this.orgInfo = res.data;
          this.serverService?.dismissLoader();
          this.checkForSSO();
          this.checkForAuditMode();
        },
        error: (error) => {
          this.serverService?.dismissLoader();
          console.log('Sso', error);
        },
      });
  }

  checkForSSO(): void {
    if (this.orgInfo.samlConnectionData && this.orgInfo.ssoConfig) {
      localStorage.setItem('connection_id', this.orgInfo.samlConnectionData.connection_id);
    }
  }

  checkForAuditMode(): void {
    if (this.orgInfo.auditMode) {
      localStorage.setItem('auditMode', 'enabled');
    } else {
      localStorage.setItem('auditMode', 'disabled');
    }
  }
}
