import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-modal-header',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './modal-header.component.html',
  styleUrl: './modal-header.component.css',
  host: {
    "[style.width]": `"100%"`
  }
})
export class ModalHeaderComponent {
  title: string = '';

  constructor(
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.title = dialogConfig.data['title'];
  }


  closeDialog(): void {
    this.dialogRef.close();
  }

}
