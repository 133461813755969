<div class="d-flex align-items-center justify-content-between w-100">
    <div class="align-items-center create-signal-logo-wrap d-flex">
        <div class="create-signal-logo">
            <img src="../../../assets/images/logo.png" alt="logo" class="img-fluid img-1" />
        </div>
        <h3 class="mx-5">
            {{title}}
        </h3>
    </div>

    <div class="close-signal">
        <p-button icon="pi pi-times" severity="secondary " [rounded]="true" [text]="true" (onClick)="closeDialog()">
        </p-button>
    </div>
</div>