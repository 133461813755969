import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteNotFoundComponent } from './shared/components/route-not-found/route-not-found.component';
import { SetPasswordComponent } from './shared/components/set-password/set-password.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { CallbackComponent } from './shared/components/callback/callback.component';
import { OrganizationLayoutComponent } from './layouts/organization/organization-layout/organization-layout.component';
import { SuperAdminLayoutComponent } from './layouts/super-admin/super-admin-layout/super-admin-layout.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { SuperAdminAuthGuard } from './guards/super-admin/super-admin.guard';
import { ProjectLayoutComponent } from './layouts/project/project-layout/project-layout.component';
import { AuthorizationGuard } from './guards/org-admin-authorization/authorization.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'organization',
    canActivate: [AuthGuard],
    component: OrganizationLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./modules/organization-admin/organization-admin.module').then((m) => m.OrganizationAdminModule),
    }]
  },
  {
    path: 'admin',
    canActivate: [SuperAdminAuthGuard],
    component: SuperAdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./modules/super-admin/super-admin.module').then((m) => m.SuperAdminModule)
    }]
  },
  {
    path: 'projects',
    component: ProjectLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./modules/projects-admin/projects-admin.module').then((m) => m.ProjectsAdminModule)
    }],
    canActivate: [AuthorizationGuard],
    data: { privilegeRequired: 'projects' },
  },
  {
    path: 'api/callback',
    component: CallbackComponent
  },
  {
    path: 'set-password',
    component: SetPasswordComponent
  },
  {
    path: 'reset-password/:token',
    component: SetPasswordComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    component: RouteNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
